import * as React from 'react';
import { graphql } from 'gatsby';
import { MapToComponents } from 'react-map-to-components';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PortableText from '../components/PortableText';
import Accordion from '../components/Accordion';
import ThreeColumns from '../components/sections/ThreeColumns';
import TwoColumns from '../components/sections/TwoColumns';
import SingleColumn from '../components/sections/SingleColumn';
import Testimony from '../components/sections/Testimony';
import Hero from '../components/sections/Hero';
import BlockQuestions from '../components/sections/BlockQuestions';
import More from '../components/sections/More';
import Table2Columns from '../components/sections/Table2Columns';
import Table3Columns from '../components/sections/Table3Columns';
import TwoColumnsQuery from '../fragments/TwoColumns';
import ThreeColumnsQuery from '../fragments/ThreeColumns';
import SingleColumnQuery from '../fragments/SingleColumn';
import HeroQuery from '../fragments/Hero';
import BlockQuestionsQuery from '../fragments/BlockQuestions';
import MoreQuery from '../fragments/More';
import Table2ColumnsQuery from '../fragments/Table2Columns';
import Table3ColumnsQuery from '../fragments/Table3Columns';

import SEO from '../components/SEO';

const FAQPageStyles = styled.div`
  margin: var(--margin) 0;

  .section-title {
    margin-bottom: var(--padding);
    text-align: center;
  }

  .accordion-container {
    border-top: 1px solid var(--blue-dark);
  }
`;

const FAQPage = ({ data, location }) => {
  const faqPage = data.faq.nodes[0];
  const questions = data.faq.nodes[0].questionsList;

  return (
    <FAQPageStyles>
      <SEO title="Page FAQ" location={location} />
      <div className="container container--md">
        {faqPage.image?.asset && (
          <GatsbyImage
            image={getImage(faqPage.image.asset)}
            alt={faqPage.image.altText || faqPage.title}
            className="image-header"
          />
        )}
        <h1 className="section-title">{faqPage.title}</h1>
        <MapToComponents
          getKey={(section) => section.id || section._key}
          getType={(section) => section._type}
          list={faqPage.content}
          map={{
            threeColumns: ThreeColumns,
            twoColumns: TwoColumns,
            singleColumn: SingleColumn,
            hero: Hero,
            testimonySection: Testimony,
            blockQuestions: BlockQuestions,
            more: More,
            table2columns: Table2Columns,
            table3columns: Table3Columns,
          }}
          mapDataToProps={{
            threeColumns: ({ data }) => ({
              title: data.title,
              bgTitle: data.bgTitle?.value,
              bgSection: data.bgSection?.value,
              showTitle: data.showTitle,
              cards: data.cards,
              titleOptions: data.titleOptions,
            }),
            twoColumns: ({ data }) => ({
              alignContent: data.alignContent,
              title: data.title,
              showTitle: data.showTitle,
              bgColor: data.bgColor?.value,
              cards: data.cards,
              colsProportions: data.colsProportions,
              ctaButton: data.ctaButton,
              titleOptions: data.titleOptions,
            }),
            singleColumn: ({ data }) => ({
              background: data.background?.value,
              alignCenter: data.alignCenter,
              ctaButton: data.ctaButton,
              description: data.columnDescription?._rawText,
              title: data.title,
              showTitle: data.showTitle,
              titleOptions: data.titleOptions,
            }),
            hero: ({ data }) => ({
              pageTitle: title,
              images: data.images,
            }),
            testimonySection: ({ data }) => ({
              testimonies: data.testimonyRef,
              cta: data.cta,
              ctaBg: data.ctaBg?.value,
              ctaBgHover: data.ctaBghover?.value,
              ctaColor: data.ctaColor?.value,
              ctaColorHover: data.ctaColorHover?.value,
            }),
            blockQuestions: ({ data }) => ({
              background: data.background?.value,
              questions: data.questionsList,
              showTitle: data.showTitle,
              title: data.title,
              titleOptions: data.titleOptions,
            }),
            more: ({ data }) => ({
              text: data.moreText,
              ctaButton: data.ctaButton,
            }),
            table2columns: ({ data }) => ({
              rows: data.rows,
            }),
            table3columns: ({ data }) => ({
              rows: data.rows,
            }),
          }}
        />

        <div className="accordion-container">
          {questions.map((question) => (
            <Accordion
              key={question.id}
              title={question.title}
              background="#FFFFFF"
            >
              <PortableText blocks={question.reponseText._rawText} />
            </Accordion>
          ))}
        </div>
      </div>
    </FAQPageStyles>
  );
};

export default FAQPage;

export const query = graphql`
  query FAQQuery {
    faq: allSanitySingletonFaq {
      nodes {
        title
        image {
          asset {
            altText
            gatsbyImageData(width: 900)
          }
        }
        content {
          ...SingleColumnQuery
          ...TwoColumnsQuery
          ...ThreeColumnsQuery
          ...HeroQuery
          ...MoreQuery
          ...BlockQuestionsQuery
          ...Table2ColumnsQuery
          ...Table3ColumnsQuery
        }
        questionsList {
          id
          title
          reponseText {
            _rawText(resolveReferences: { maxDepth: 10 })
          }
        }
      }
    }
  }
`;
